/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useState, useEffect, forwardRef } from 'react';

import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
  baseURL: 'https://inaarees.gov.ao/backend/'
});

function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const [file, setFile] = useState(React.createRef());
  const [tipo, setTipo] = useState();
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const [sms, setSms] = useState();
  const [alertsms, setAlertsms] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  

  const columns = [
    { title: 'Foto', field: 'foto', editable: 'never', render: (rowData) => <img src={`https://inaarees.gov.ao/backend/u/${rowData.foto}`} style={{width: "40px", borderRadius: "100%"}} />},
    { title: 'Nome', field: 'primeiroNome' },
    { title: 'Sobrenome', field: 'ultimoNome' },
    { title: 'Email', field: 'email' },
    { title: 'Tipo', field: 'tipo', render: (rowData) => <p>{rowData.tipo}</p> ,initialEditValue: '0', lookup: { 0: 'Admin', 1: 'Normal' }},
    { title: 'Senha', render: (rowData) => <Button onClick={(e)=>{handleReset(rowData)}} variant="contained" style={{backgroundColor: 'rgb(0, 90, 0)', color: 'white'}}>Reset</Button> }
  ];
  const [data, setData] = useState([]); // table data

  // for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    api.get('/users')
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log('Error');
      });
  }, []);

  const handleReset = (rowData) =>{
    const da = new FormData();
    console.log("rr")
    da.append("reset", true)
    api.post(`/users/${rowData.id}`, da)
      .then((res) => {
        console.log(res.data);
        setSms("As alterações foram salvas com sucesso!")
        setAlertsms("success")
        handleClick();
      })
      .catch((error) => {
        console.log('Error');
        setSms("Por favor verifique as informações!")
        setAlertsms("error")
        handleClick();
      });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    // validation
    const errorList = [];

    if (errorList.length < 1) {
      const da = new FormData();
    if (newData.primeiroNome === undefined) {
      errorList.push('Insira o Nome');
    }
    if (newData.ultimoNome === undefined) {
      errorList.push('Insira o Sobrenome');
    }
    if (newData.tipo == 0)
      da.append("tipo" , "Admin");
    if(newData.tipo == 1)
      da.append("tipo" , "Normal");

      da.append('primeiroNome', newData.primeiroNome);
      da.append('ultimoNome', newData.ultimoNome);
      da.append('email', newData.email);
      
      console.log(newData.tipo)
      api.post(`/users/${newData.id}`, da)
        .then((res) => {
          console.log(res);
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          newData.primeiroNome = res.data.primeiroNome;
          newData.ultimoNome = res.data.ultimoNome;
          newData.email = res.data.email;
          newData.tipo = res.data.tipo;
          
          
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(['Ups. Erro ao actualizar os dados...']);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    // validation
    const errorList = [];
   
    if (newData.primeiroNome === undefined) {
      errorList.push('Insira o Nome');
    }
    if (newData.ultimoNome === undefined) {
      errorList.push('Insira o Sobrenome');
    }
    if (newData.email === undefined) {
      errorList.push('Insira o Email');
    }
    if (newData.tipo === undefined) {
      errorList.push('Insira o Tipo');
    }

    if (errorList.length < 1) { // no error
      const da = new FormData();

    if (newData.tipo == 0)
      da.append("tipo" , "Admin");
    if(newData.tipo == 1)
      da.append("tipo" , "Normal");

      da.append('primeiroNome', newData.primeiroNome);
      da.append('ultimoNome', newData.ultimoNome);
      da.append('email', newData.email);

      api.post('/users', da)
        .then((res) => {
          console.log(res);
          const dataToAdd = [...data];
          newData.foto = res.data.foto;
          newData.primeiroNome = res.data.primeiroNome;
          newData.ultimoNome = res.data.ultimoNome;
          newData.email = res.data.email;
          newData.tipo = res.data.tipo;
          

          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages(['Não foi possível adicionar dados. Server error!']);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    api.delete(`/users/${oldData.id}`)
      .then((res) => {
        console.log(res);
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages(['Falha ao eliminar! Server error']);
        setIserror(true);
        resolve();
      });
  };

  return (
    <>

      <Grid container spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={12}>
          <div>
            {iserror
              && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
              )}
          </div>
          <MaterialTable
            title="Usuários"
            localization={{ body: { editRow: { deleteText: 'Deseja realmente eliminar?' } } }}
            columns={columns}
            data={data}
            icons={tableIcons}
            editable={{
              onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
              onRowAdd: (newData) => new Promise((resolve) => {
                handleRowAdd(newData, resolve);
              }),
              onRowDelete: (oldData) => new Promise((resolve) => {
                handleRowDelete(oldData, resolve);
              }),
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertsms}>
          {sms}
        </Alert>
    </Snackbar>
    </>
  );
}

export default App;
