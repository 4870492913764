/* eslint-disable no-multi-str */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link, useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import Alert from '@material-ui/lab/Alert';
import MyStatefulEditor from './edit';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
  baseURL: 'https://inaarees.gov.ao/backend'
});

function App() {
  let navigate = useNavigate();
  const [file, setFile] = useState(React.createRef());
  let v;

  const columns = [
    { title: 'Título', field: 'titulo' },
    {
      title: 'Descrição',
      field: 'descricao',
      render: (rowData) => (
        <Editor
          initialValue={rowData.descricao}
          disabled
          apiKey="4pmok9wxp67i2veuxka0uxaum0wrjckaca1tkxan2vs59iby"
          init={{
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
          }}
        />
      )
    },

  ];
  const [data, setData] = useState([]); // table data

  // for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    api.get('/categoria/getJson')
      .then((res) => {
        console.log(res.data['Homologação de Estudos'].Info);
        setData(res.data['Homologação de Estudos'].Info);
      })
      .catch((error) => {
        console.log('Error');
      });
  }, []);

  const handleRowDelete = (oldData, resolve) => {
    axios.delete(`https://inaarees.gov.ao/backend/infocategoria/${oldData.id}`)
      .then((res) => {
        console.log(res);
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages(['Falha ao eliminar! Server error']);
        setIserror(true);
        resolve();
      });
  };

  return (
    <>

      <Grid container spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={12}>
          <div>
            {iserror
              && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
              )}
          </div>
          <div style={{ margin: '6px', display: 'flex', justifyContent: 'flex-end' }}>
            <Link to="/backoffice/app/homologacao/adicionar/novo">
              <Button
                style={{ backgroundColor: 'rgb(9, 94, 178)', color: 'white' }}
                startIcon={<AddIcon />}
                variant="contained"
                size="small"
              >
                Adicionar
              </Button>
            </Link>
          </div>
          <MaterialTable
            title="Homologações"
            localization={{ body: { editRow: { deleteText: 'Deseja realmente eliminar?' } } }}
            columns={columns}
            data={data}
            icons={tableIcons}
            actions={[
              {
                icon: () => <Edit />,
                tooltip: 'Editar',
                onClick: (event, rowData) => { navigate(`/backoffice/app/homologacao/adicionar/${rowData.id}`)} 
              }
            ]}
            editable={{
              onRowDelete: (oldData) => new Promise((resolve) => {
                handleRowDelete(oldData, resolve);
              }),
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </>
  );
}

export default App;
