/* eslint-disable linebreak-style */
import { useCallback, useState, useContext } from 'react';
import axios from 'axios';
import { AppContext } from './context';


const api = axios.create({
  baseURL: 'https://inaarees.gov.ao/backend'
});

export default function useLoginApi() {
  
  const { data: dataContext } = useContext(AppContext);
  const [carregando, setCarregando] = useState();
  

  const logar = useCallback(async (email, senha) => {
    setCarregando(true);

    try {
      const dados = new FormData();

      dados.append('email', email);
      dados.append('senha', senha);
      const retorno = await api.post('/autenticar', dados);
      console.log(retorno);
      console.log(dataContext);
      if (retorno.status === 200) {
  
        dataContext.setState({
          usuario: retorno.data
        });
      }
    } catch (err) {
      console.log('erro', err);
  
    } finally {
      setCarregando(false);
    }
  }, [dataContext]);

  return [logar, carregando]
}
