/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import { Link , useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import useLoginForm from './hooks/useLoginForm';
import useLoginApi from './hooks/useLoginApi';
import styles from './LoginPage.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [form, setField] = useLoginForm();
  const [logar, carregando] = useLoginApi();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          
          <div className={styles.content}>
          
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{marginBottom: "8px"}}
            >
              INAAREES - BACKOFFICE
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Preencha os campos abaixo para entrar na Plataforma
            </Typography>
            
            <div>
              <TextField
                value={form.email || ''}
                type="email"
                fullWidth
                onChange={(e) => setField('email', e.target.value)}
                label="Email"
                className={styles.input}
              />
            </div>
            <div className={styles.row}>
            <form onSubmit={(e) => {
              e.preventDefault();
              logar(form.email, form.senha)
            }}>
              <TextField
                value={form.senha || ''}
                type="password"
                fullWidth
                onChange={(e) => setField('senha', e.target.value)}
                label="Senha"
                className={styles.input}
              />
            </form>
            </div>
            <div className={styles.row}>
              <Button
                variant="contained"
                disabled={!form.email || !form.senha || carregando}
                onClick={() => logar(form.email, form.senha)}
                className={styles.buttonLogin}
                color="primary"
              >
                {carregando ? 'Entrando...' : 'Entrar'}
              </Button>
            </div>
            <div className={styles.row}>
              <Link to="/backoffice/emailConfirm">
                <Button
                  variant="contained"
                  className={styles.reset}
                  color="primary"
                >
                  Esqueceu a sua senha ?
                </Button>
              </Link>
            </div>
            
            </Box>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
