/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
} from 'react-feather';
import InputIcon from '@material-ui/icons/Input';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import PeopleIcon from '@material-ui/icons/People';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import NavItem from './NavItem';
import { AppContext } from '../../../views/auth/hooks/context';

const user = {
  avatar: '/static/images/avatars/avatar_5.png',
  jobTitle: 'Desenvolvedor Sênior',
  name: 'Apolinário Manuel'
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 66,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },

  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const { data: dataContext } = useContext(AppContext);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={`https://inaarees.gov.ao/backend/u/${dataContext?.state?.usuario?.foto}`}
          to="/backoffice/app/settings"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {dataContext?.state?.usuario?.nome}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {dataContext?.state?.usuario?.tipo}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List
          component="nav"
          className={classes.root}
        >
          <RouterLink to="/backoffice/app/dashboard">
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText secondary="Pagina Inicial" />
            </ListItem>
          </RouterLink>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText secondary="Home" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <RouterLink to="/backoffice/app/banner">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ViewCarouselIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Banner" />
                </ListItem>
              </RouterLink>
              <RouterLink to="/backoffice/app/anuncios">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Anúncio" />
                </ListItem>
              </RouterLink>
              <RouterLink to="/backoffice/app/homologacoes">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Homologação" />
                </ListItem>
              </RouterLink>
              <RouterLink to="/backoffice/app/reconhecimentos">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <CardMembershipIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Reconhecimento" />
                </ListItem>
              </RouterLink>
            </List>
          </Collapse>
          <RouterLink to="/backoffice/app/products">
            <ListItem button>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText secondary="Homologação" />
            </ListItem>
          </RouterLink>
          <RouterLink to="/backoffice/app/reconhecimento">
            <ListItem button>
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText secondary="Reconhecimento" />
            </ListItem>
          </RouterLink>
          {
            (dataContext?.state?.usuario?.tipo == 'Admin')
              ? (
                <RouterLink to="/backoffice/app/users">
                  <ListItem button>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText secondary="Usuarios" />
                  </ListItem>
                </RouterLink>
              )
              : null
          }

          <RouterLink to="/backoffice/app/settings">
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText secondary="Configurações" />
            </ListItem>
          </RouterLink>

          <ListItem button onClick={() => dataContext?.setState(null)}>
            <ListItemIcon>
              <InputIcon />
            </ListItemIcon>
            <ListItemText secondary="Sair" />
          </ListItem>

        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
