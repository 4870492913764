/* eslint-disable linebreak-style */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ContextProvider from './views/auth/hooks/context';

ReactDOM.render((
  
    <BrowserRouter >
      <ContextProvider>
        <App />
      </ContextProvider>
    </BrowserRouter>
  
), document.getElementById('root'));

