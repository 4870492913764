/* eslint-disable linebreak-style */
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { AppContext } from '../../../views/auth/hooks/context';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  
  const { data: dataContext } = useContext(AppContext);
  const [sms, setSms] = useState();
  const [alertsms, setAlertsms] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [values, setValues] = useState({
    firstName: dataContext?.state?.usuario?.nome?.split(' ')[0],
    lastName: dataContext?.state?.usuario?.nome?.split(' ')[1],
    password: '',
    email: dataContext?.state?.usuario?.email,
    country: dataContext?.state?.usuario?.tipo
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const dados = new FormData();

    dados.append('primeiroNome', values.firstName);
    dados.append('ultimoNome', values.lastName);
    dados.append('email', values.email);
    dados.append("senha" , values.password);
    axios.post(`https://inaarees.gov.ao/backend/users/${dataContext?.state?.usuario?.id}`, dados)
      .then((res) => {
        console.log(res);
        let valor = res.data.primeiroNome + " " + res.data.ultimoNome
      
        dataContext.setState({
          usuario:{
            email: res.data.email,
            foto: res.data.foto,
            id: res.data.id,
            nome: valor ,
            tipo: res.data.tipo
        }})
        setSms("As alterações foram salvas com sucesso!")
        setAlertsms("success")
        handleClick();
      })
      .catch((error) => {
        setSms("Por favor verifique as informações!")
        setAlertsms("error")
        handleClick();
        console.log(error);
      });
    
  }

  return (
    <>
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Editando informações"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Especifique seu nome completo"
                label="Primeiro Nome"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Último nome"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            Salvar Alterações
          </Button>
        </Box>
      </Card>
    </form>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertsms}>
          {sms}
        </Alert>
    </Snackbar>
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
