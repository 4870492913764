/* eslint-disable linebreak-style */
import React, { useState, useContext} from 'react';
import { Outlet, useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { AppContext } from '../../views/auth/hooks/context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  p: {
    padding: '30px 30px 30px 0px',
    marginLeft: '4%',
    width: '88%',
    fontSize: '17px',
    fontFamily: 'sans-serif',
    textAlign: 'justify'
  },
  root1: {
    width: '90.5%',
    height: 'auto',
    margin: '3.5% 4%'
  },
}));

const Title = styled.div`
  padding: 30px 30px 30px 0px;
  font-size: 20px;
  width: 86%;
  margin-left: 4%;
  color: rgb(9, 94, 178);
  border-bottom: 1px solid #ccc;
`;

const DashboardLayout = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { data: dataContext } = useContext(AppContext);

  return (
    <div className={classes.root}>
      {(dataContext?.state?.usuario)? <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} /> : navigate("/backoffice/login")}
      
      {(dataContext?.state?.usuario)? <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} /> : navigate("/backoffice/login")}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {(dataContext?.state?.usuario)? <Outlet /> : navigate("/backoffice/login")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
