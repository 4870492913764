/* eslint-disable camelcase */
/* eslint-disable linebreak-style */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import img_1 from './107525027_3562834047096632_3125853819288617452_n.jpg';
import { AppContext } from '../../../views/auth/hooks/context';
import axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '26.5px 0px'
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: '15px'
  }
}));

const Profile = ({ className, ...rest }) => {
  const { data: dataContext } = useContext(AppContext);
  const classes = useStyles();
  const [file, setFile] = useState(`https://inaarees.gov.ao/backend/u/${dataContext?.state?.usuario?.foto}`);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));

    const da = new FormData();

    da.append("image", event.target.files[0])
    console.log(event.target.files[0])
    axios.post(`https://inaarees.gov.ao/backend/users/${dataContext?.state?.usuario?.id}`, da)
    .then((res) => {
      console.log(res)
      let valor = res.data.primeiroNome + " " + res.data.ultimoNome
      
      dataContext.setState({
        usuario:{
          email: res.data.email,
          foto: res.data.foto,
          id: res.data.id,
          nome: valor ,
          tipo: res.data.tipo
      }})
      handleClick();
      setFile(`https://inaarees.gov.ao/backend/u/${res.data.foto}`);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={file}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {dataContext?.state?.usuario?.nome}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {dataContext?.state?.usuario?.tipo}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions style={{display: "block"}}>
        <Button
          color="primary"
          fullWidth
          variant="text"
          for="file"
         
        >
          <label for="file" >Carregar imagem</label>
        </Button>
        <p></p>
        <input style={{ visibility: "hidden"}} type="file" id="file" onChange={handleChange}/>
      </CardActions>
    </Card>

    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          A imagem foi carregada com sucesso!
        </Alert>
      </Snackbar>
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
