import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import CustomerListView from 'src/views/customer/CustomerListView';
import UsersListView from 'src/views/users/UsersListView';
import Add from 'src/views/reco/add';
import Add2 from 'src/views/product/ProductListView/add';
import AnuncioListView from 'src/views/anuncio/AnuncioListView';
import HomologacaoListView from 'src/views/homologacoes/HomologacaoListView';
import ReconhecimentoListView from 'src/views/reconhecimentos/ReconhecimentoListView';
import Reco from 'src/views/reco';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import ResetView from 'src/views/auth/reset';
import SenhaResetView from 'src/views/auth/senhaReset';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import SettingsView from 'src/views/settings/SettingsView';

const routes = [
  {
    path: '/backoffice/app',
    element: <DashboardLayout />,
    children: [
      { path: '/banner', element: <CustomerListView /> },
      { path: '/anuncios', element: <AnuncioListView /> },
      { path: '/adicionar/novo', element: <Add /> },
      { path: '/adicionar/:id', element: <Add /> },
      { path: '/homologacao/adicionar/novo', element: <Add2 /> },
      { path: '/homologacao/adicionar/:id', element: <Add2 /> },
      { path: '/homologacoes', element: <HomologacaoListView /> },
      { path: '/reconhecimentos', element: <ReconhecimentoListView /> },
      { path: '/reconhecimento', element: <Reco /> },
      { path: '/dashboard', element: <DashboardView /> },
      { path: '/products', element: <ProductListView /> },
      { path: '/users', element: <UsersListView /> },
      { path: '/settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/backoffice',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Navigate to="/backoffice/login" /> },
      { path: '/login', element: <LoginView /> },
      { path: '/emailConfirm', element: <ResetView /> },
      { path: '/reset/:id', element: <SenhaResetView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
