/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, {useState} from 'react';
import { Link , useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import styles from './LoginPage.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SenhaResetView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [senha, setSenha] = useState();
  const [confirmSenha, setConfirmSenha] = useState();

  const [sms, setSms] = useState();
  const [alertsms, setAlertsms] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e, senha, confirmSenha) =>{
    e.preventDefault();
    const da = new FormData();

    da.append("password", senha);
    console.log(senha, ' ', confirmSenha);
    axios.post(`https://inaarees.gov.ao/backend/reset_password/${id}`, da)
    .then(res => {
      console.log(res);
      setSms("Senha resetada com sucesso!")
      setAlertsms("success")
      handleClick();
      setTimeout(() => {
        navigate('/backoffice/login')
      }, 3003);
    })
    .catch(error => {
      console.log(error);
      setSms("Por favor verifique as informações!")
      setAlertsms("error")
      handleClick();
    })
  }

  return (
    <Page
      className={classes.root}
      title="Reset"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          
          <div className={styles.content}>
          
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{marginBottom: "8px"}}
            >
              INAAREES - BACKOFFICE
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Preencha correctamente os campos abaixo para resetar a sua senha!
            </Typography>
            <div className={styles.row}>
              <TextField
                type="password"
                value={senha || ''}
                fullWidth
                onChange={(e) => setSenha(e.target.value)}
                label="Nova Senha"
                className={styles.input}
              />
            </div>
            <div className={styles.row}>
            <form onSubmit={(e)=> handleSubmit(e, senha, confirmSenha)}>
              <TextField
                type="password"
                value={confirmSenha || ''}
                fullWidth
                onChange={(e) => setConfirmSenha(e.target.value)}
                label="Digite a senha novamente"
                className={styles.input}
              />
            </form>
            </div>
            <div className={styles.row}>
              <Button
                variant="contained"
                disabled={!senha || !confirmSenha}
                onClick={(e) => handleSubmit(e, senha, confirmSenha)}
                className={styles.buttonLogin}
                color="primary"
              >
                Enviar
              </Button>
            </div>
            </Box>
          </div>
        </Container>
      </Box>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertsms}>
            {sms}
          </Alert>
      </Snackbar>
    </Page>
  );
};

export default SenhaResetView;
