/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import React, { useState, useEffect, forwardRef } from 'react';

import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
  baseURL: 'https://inaarees.gov.ao/backend'
});

function validateEmail(email) {
  const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function App() {
  const [file, setFile] = useState(React.createRef());

  const columns = [
    { title: 'id', field: 'id', hidden: true },
    {
      title: 'Imagem',
      field: 'img',
      editComponent: (props) => {
        return (
          <input
            type="file"
            ref={file}
            onChange={(e) => {
              console.log(e.target.files[0]);
              const data = { ...props.rowData };
              data.name = e.target.value;
              data.surname = e.target.value.toLocaleUpperCase();
              props.onRowDataChange(data);

              console.log(file.current.files[0].name);
            }}
          />
        );
      },
      render: (rowData) => <img src={`https://inaarees.gov.ao/backend/u/${rowData.img}`} style={{ width: 120, height: 80 }} />
    },
    { title: 'Título', field: 'titulo' },
    { title: 'Conteúdo', field: 'conteudo' },
    { title: 'Destaque', render: (rowData) => <Radio checked={(rowData.id == selectedId)? true : false} onChange={(e)=>{handleChange(e, rowData)}} value={(rowData.destaque == 1)? true : false} />}
  ];
  

  const [data, setData] = useState([]); // table data

  // for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedId, setSelectedId] = React.useState();

  const handleChange = (event, item) => {
    
    const da = new FormData();
    console.log(item.id)
    da.append("destaque", 1)
    api.post(`/banner/${item.id}`, da)
      .then((res) => {
        console.log(res.data);
        setSelectedId(res.data.id);
      })
      .catch((error) => {
        console.log('Error');
      });
  };
 
  useEffect(() => {
    api.get('/banner')
      .then((res) => {
        setData(res.data);
        res.data.map((item) => {
          if(item.destaque == 1){
            setSelectedId(item.id)
          }
        })
      })
      .catch((error) => {
        console.log('Error');
      });
  }, []);

  const handleRowUpdate = (newData, oldData, resolve) => {
    // validation
    const errorList = [];

    if (newData.titulo === '') {
      errorList.push('Insira o título');
    }
    if (newData.conteudo === '') {
      errorList.push('Insira o conteúdo');
    }

    if (errorList.length < 1) {
      const da = new FormData();

      if (file.current.files[0] === undefined) {
        console.log('img ');
      } else {
        console.log('img ', file.current.files[0].name);
        da.append('image', file.current.files[0]);
      }

      da.append('titulo', newData.titulo);
      da.append('conteudo', newData.conteudo);

      api.post(`/banner/${newData.id}`, da)
        .then((res) => {
          console.log(res);
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          console.log(newData);
          newData.id = res.data.id;
          newData.conteudo = res.data.conteudo;
          newData.img = res.data.img;
          newData.titulo = res.data.titulo;
          
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(['Ups. Erro ao actualizar os dados...']);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    // validation
    const errorList = [];
    if (file.current.files[0].name === undefined) {
      errorList.push('Insira a imagem');
    }
    if (newData.titulo === undefined) {
      errorList.push('Insira o título');
    }
    if (newData.conteudo === undefined) {
      errorList.push('Insira o conteúdo');
    }

    if (errorList.length < 1) { // no error
      const da = new FormData();

      da.append('image', file.current.files[0]);
      da.append('titulo', newData.titulo);
      da.append('conteudo', newData.conteudo);

      api.post('/banner', da)
        .then((res) => {
          console.log(res);
          const dataToAdd = [...data];
          newData.id = res.data.id;
          newData.conteudo = res.data.conteudo;
          newData.img = res.data.img;
          newData.titulo = res.data.titulo;
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages(['Não foi possível adicionar dados. Server error!']);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    api.delete(`/banner/${oldData.id}`)
      .then((res) => {
        console.log(res);
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages(['Falha ao eliminar! Server error']);
        setIserror(true);
        resolve();
      });
  };

  return (
    <>

      <Grid container spacing={1}>
        <Grid item xs={3} />
        <Grid item xs={12}>
          <div>
            {iserror
              && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
              )}
          </div>
          <MaterialTable
            title="Banner"
            localization={{ body: { editRow: { deleteText: 'Deseja realmente eliminar?' } } }}
            columns={columns}
            data={data}
            icons={tableIcons}
            editable={{
              onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
              onRowAdd: (newData) => new Promise((resolve) => {
                handleRowAdd(newData, resolve);
              }),
              onRowDelete: (oldData) => new Promise((resolve) => {
                handleRowDelete(oldData, resolve);
              }),
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </>
  );
}

export default App;
