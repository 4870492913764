import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import styled from 'styled-components'; 

const Div = styled.div`
  .logo{
    margin-left: 16px;
    @media (max-width: 1253px){
      margin-left: 0px;
    }
  }
`

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Div>
      <AppBar
        style={{backgroundColor: "white", borderBottom: "2px solid #095eb2" }}
        className={clsx(classes.root, className)}
        elevation={0}
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <RouterLink to="/backoffice/login">
            <Logo className="logo"/>
          </RouterLink>
        </Toolbar>
      </AppBar>
    </Div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
