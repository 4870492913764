/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  p: {
    padding: '30px 30px 30px 0px',
    marginLeft: '4%',
    width: '88%',
    fontSize: '17px',
    fontFamily: 'PT Sans, sans-serif !important',
    textAlign: 'justify',
    lineHeight: "1.5"
  },
  root1: {
    width: '90.5%',
    height: 'auto',
    margin: '3.5% 4%'
  },
}));

const Title = styled.div`
  padding: 30px 30px 30px 0px;
  font-size: 20px;
  width: 86%;
  margin-left: 4%;
  color: rgb(9, 94, 178);
  border-bottom: 1px solid #ccc;
  font-family: PT Sans, sans-serif !important;
`;

const Dashboard = () => {
  const classes = useStyles();

  return (
          <div>
            <Paper className={classes.root1}>
              <Title>Seja Bem Vindo !</Title>
              <p className={classes.p}>
                Seja Bem-Vindo ao BACKOFFICE do INAAREES!
              </p>
            </Paper>
            </div>
      
  );
};

export default Dashboard;
