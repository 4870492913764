/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, createContext } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import useLocalStorage from '../../../shared/hooks/useLocalStorage';

export const AppContext = createContext({
  state: {},
  setState: () => null
});

function ContextProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useLocalStorage('@app');

  useEffect(() => {
    (() => {
      if (state?.usuario && !location.pathname.includes('app')) {
        navigate('/backoffice/app/dashboard');
      }

      if (!state?.usuario && location.pathname.includes('app')) {
        navigate('/backoffice/login');
      }
    })();
  }, [state]);

  return (
    <AppContext.Provider
      value={
        {
          data: {
            state,
            setState
          }
        }
      }
    >
      {children}
    </AppContext.Provider>
  );
}

export default ContextProvider;
