import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import Avatar from 'react-avatar';
import Grid from '@material-ui/core/Grid'

import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const api = axios.create({
  baseURL: `https://inaarees.gov.ao/backend`
})

function App() {

  const [file, setFile] = useState(React.createRef())

  var columns = [
    {title: "id", field: "id", editable: "never"},
    {title: "Título", field: "titulo"},
    {title: "Imagem", field: "img" , editComponent: props => {
      
      return (
        <input
          type="file"
          ref={file}
          onChange={e => {
            console.log(e.target.files[0])
            var data = { ...props.rowData };
            data.name = e.target.value;
            data.surname = e.target.value.toLocaleUpperCase();
            props.onRowDataChange(data);
            
            console.log(file.current.files[0].name)
          }}
        />
      )
    },
    render: rowData => <img src={`https://inaarees.gov.ao/backend/u/${rowData.img}`} style={{width: 80}}/> 
  },
    {title: "Preço", field: "span"},
  ]
  const [data, setData] = useState([]); //table data

  //for error handling
  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => { 
    api.get("/categoria/home")
          .then(res => {          
            console.log(res.data[1].Items);     
            setData(res.data[1].Items)
          })
         .catch(error=>{
             console.log("Error")
         })
  }, [])

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = []
    if(newData.titulo === ""){
      errorList.push("Insira o Título")
    }
    if(newData.span === ""){
      errorList.push("Insira o Preço")
    }

    if(errorList.length < 1){
      const da = new FormData();

      da.append("titulo", newData.titulo);
      da.append("preco", newData.span);
      
      if(file.current.files[0] === undefined){
        console.log("img ")
      }
      else{
        console.log("img ", file.current.files[0].name)
        da.append("image", file.current.files[0]);
      }

      api.post("/itemcategoria/"+newData.id, da)
      .then(res => {
        console.log(res);
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        newData.id = res.data.id;
        newData.titulo = res.data.titulo;
        newData.span = res.data.preco;
        newData.img = res.data.img;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);
        resolve()
        setIserror(false)
        setErrorMessages([])
      })
      .catch(error => {
        setErrorMessages(["Ups. Erro ao actualizar os dados..."])
        setIserror(true)
        resolve()
        
      })
    }else{
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }
    
  }

  return (
    <>
      
      <Grid container spacing={1}>
          <Grid item xs={3}></Grid>
          <Grid item xs={12}>
          <div>
            {iserror && 
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }       
          </div>
            <MaterialTable
              title="Reconhecimentos"
              localization={{ body: { editRow: { deleteText: 'Deseja realmente eliminar?' } } }}
              columns={columns}
              data={data}
              icons={tableIcons}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                      handleRowUpdate(newData, oldData, resolve);
                  })
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
    </>
  );
}

export default App;
