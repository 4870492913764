/* eslint-disable linebreak-style */
import React from 'react';
import logo from '../img/logo.png';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={logo}
      {...props}
    />
  );
};

export default Logo;
