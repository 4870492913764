/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import styled from 'styled-components'; 

const Div = styled.div`
  .logo{
    margin-left: 16px;
    @media (max-width: 1253px){
      margin-left: 0px;
    }
  }
`

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);

  return (
    <Div>
      <AppBar
        style={{backgroundColor: "white", borderBottom: "2px solid #095eb2" }}
        className={clsx(classes.root, className)}
        elevation={0}
        {...rest}
      >
        <Toolbar>
          <RouterLink to="/backoffice/app/dashboard">
            <Logo className="logo"/>
          </RouterLink>
          <Box flexGrow={1} />
          <Hidden lgUp>
            <IconButton
              color="black"
              onClick={onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
